import { Outlet } from "react-router-dom";
import React from "react";
export const MobileViewLayout: React.FC = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        {/* <div className="pt-[76px]"> */}
        <Outlet></Outlet>
        {/* </div> */}
      </div>
    </>
  );
};
