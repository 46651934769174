import { lazy, Suspense } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { RootLayout } from "./components/Root";
import { Home } from "./pages/Home";
import { MobileViewLayout } from "./components/MobileViewLayout";
import Backdrop from "./components/Backdrop";
const ErrorPage = lazy(() => import("./components/ErrorPage"));
const GameIframe = lazy(() => import("./pages/GameIframe"));
const TestPage = lazy(() => import("./pages/TestPage"));
const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [{ path: "/", element: <Home /> }],
    },
    {
      path: "/",
      element: <MobileViewLayout />,
      children: [
        {
          path: "/healthcheck",
          element: (
            <>
              <h1 className="mt-16 m-auto">
                If you see this healthcheck was successful
              </h1>
            </>
          ),
        },
        { path: "/oops", element: <ErrorPage /> },
      ],
    },
    {
      path: "/",
      element: <MobileViewLayout />,
      children: [
        { path: "/oops", element: <ErrorPage /> },
        { path: "/testing", element: <TestPage /> },
      ],
    },
    {
      path: "/",
      element: <Outlet></Outlet>,
      children: [
        {
          path: "/game/:gameId",
          element: (
            <Suspense
              fallback={
                <Backdrop show={true}>
                  <div className="spinner"></div>
                </Backdrop>
              }
            >
              <GameIframe />
            </Suspense>
          ),
        },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
};

export default App;
