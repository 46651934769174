import { BackIcon, LogoIcon } from "../../components/icons";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
const Navbar = () => {
  return (
    <>
      <nav className="bg-white fixed w-full z-10 border-b border-gray-200">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex">
            <button
              onClick={() => {
                history.back();
                console.log("back clicked");
              }}
              className="text-black font-semibold flex items-center"
            >
              <div className="p-4">
                <BackIcon />
              </div>
            </button>
            <button className="text-black font-semibold flex items-center">
              <span className="ml-0 font-[500] text-black text-[18px] leading-[21.6px]"></span>
            </button>
            <div className="pt-4">
              <LogoIcon></LogoIcon>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
