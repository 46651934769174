import { Fragment, PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
interface BackDropProps {
  show: boolean;
}
const Backdrop = ({
  show = false,
  children,
}: PropsWithChildren<BackDropProps>) => {
  if (!show) return null;
  return (
    <Transition
      as={Fragment}
      show={show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center ${
          show ? "" : "hidden"
        }`}
      >
        {children}
      </div>
    </Transition>
  );
};
export default Backdrop;
